import React from 'react'
import { Link } from 'gatsby'

import ErrorBoundary from '../components/common/ErrorBoundary'
import Helmet from 'react-helmet'
import Container from '../components/Container'
import Layout from '../components/Layout'
import Text from '../components/common/Text'

const NotFoundPage = () => (
  <Layout>
    <ErrorBoundary>
      <Helmet slient>
        <title>404 - Page Not Found</title>
        <meta name="description" content="Page not found" />
      </Helmet>
    </ErrorBoundary>

    <Container>
      <Text.h1 center>Page Not Found</Text.h1>
      <Text.p center html>
        Go back to <Link to="/">home</Link>.
      </Text.p>
    </Container>
  </Layout>
)

export default NotFoundPage
